import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
    return (
        <div className='footer'>
            <img src="./img/footer1.jpg" alt="" />
            <p className='copyright'> © since 2023 'KarinasCakeHouse'</p>
        </div>
    )
}

export default Footer